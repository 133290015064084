<template>
  <vx-card no-shadow title="เพิ่มบัญชีถอน">

    <!-- Info -->
    <label class="vs-input--label">เลือกรายการธนาคารที่ต้องการเพิ่มบัญชี</label>
    <v-select v-model="bankbank" :options="bankOptions" name="status" class="pt-5" :dir="$vs.rtl ? 'rtl' : 'ltr'" />
    <br>
    <h5>ข้อมูลบัญชีถอน</h5>
    <br>
    <vs-input class="w-full " v-validate="'required|numeric|max:10'" label-placeholder="หมายเลขบัญชี" v-model="bankno" name="bankno"></vs-input>
    <span class="text-danger text-sm" v-show="errors.has('bankno')">{{ errors.first('bankno') }}</span>
    <br>
    <vs-input class="w-full" v-validate="'required'" label-placeholder="ชื่อบัญชี (ชื่อ-นามสกุล)" v-model="fullname" name="fullname"></vs-input>
    <span class="text-danger text-sm" v-show="errors.has('fullname')">{{ errors.first('fullname') }}</span>
    <br>
    <vs-input class="w-full" v-validate="'required|max:20'" label-placeholder="ยูสเซอร์เนม" v-model="username" name="username"/>
    <span class="text-danger text-sm" v-show="errors.has('username')">{{ errors.first('username') }}</span>
    <br>
    <vs-input class="w-full" v-validate="'required'" label-placeholder="พาสเวิร์ด" v-model="password" name="password"></vs-input>
    <span class="text-danger text-sm" v-show="errors.has('password')">{{ errors.first('password') }}</span>
    <br>
    <vs-input class="w-full " v-validate="'required|numeric|max:10'" label-placeholder="เบอร์โทร" v-model="phone" name="phone"></vs-input>
    <span class="text-danger text-sm" v-show="errors.has('phone')">{{ errors.first('phone') }}</span>
    <vs-input v-if="bankbank.value == 'bank_transfer_kbank'" class="w-full mt-12 "  label-placeholder="K-bank Code" v-model="kbankCode" name="kbankCode"></vs-input>
    <vs-input v-if="bankbank.value == 'bank_transfer'" class="w-full mt-12  "  label-placeholder="DeviceId" v-model="deviceId" name="deviceId"></vs-input>
    <vs-input v-if="bankbank.value == 'bank_transfer_kbank' || bankbank.value == 'bank_transfer' || bankbank.value == 'kkp'" class="w-full mt-12  "  label-placeholder="PIN Code" v-model="pinCode" name="pinCode"></vs-input>
    <vs-input v-if="bankbank.value == 'kkp'" class="w-full mt-12  "  label-placeholder="เลขบัตรประชาชน" v-model="ctz_id" name="ctz_id"></vs-input>

    <!-- Save & Reset Button -->
    <div class="flex flex-wrap items-center justify-end ">
      <vs-button v-if="bankbank.value == 'kkp'" :disabled="$store.state.AppActiveUser.permissions.setting.action ? false : true" class="ml-auto mt-2" color="danger" @click="Save2()" >บันทึก</vs-button>
      <vs-button v-else :disabled="$store.state.AppActiveUser.permissions.setting.action ? false : true" class="ml-auto mt-2" color="success" @click="Save" >บันทึก</vs-button>
      <vs-button :disabled="$store.state.AppActiveUser.permissions.setting.action ? false : true" class="ml-4 mt-2" type="border" color="warning" @click="Reset">Reset</vs-button>
    </div>
  </vx-card>
</template>

<script>
import {
  Validator
} from 'vee-validate'
import axios from '../../../../axios'

import vSelect from 'vue-select'
const dict = {
  custom: {
    username: {
      required: 'กรุณากรอกข้อมูล',
      alpha_num: 'ตัวอักษรภาษาอังกฤษและตัวเลขเท่านั้น',
      max:'กรุณากรอกตัวอักษรไม่เกิน20ตัว'
    },
    password: {
      required: 'กรุณากรอกข้อมูล',
      alpha_num: 'ตัวอักษรภาษาอังกฤษและตัวเลขเท่านั้น'
    },
    fullname :{
      required : 'กรุณากรอกข้อมูล'
    },
    bankno :{
      required : 'กรุณากรอกข้อมูล',
      numeric:'กรุณากรอกตัวเลขเท่านั้น',
      max:'กรุณากรอกตัวอักษรไม่เกิน10ตัว'
    },
    phone :{
      required : 'กรุณากรอกข้อมูล',
      numeric:'กรุณากรอกตัวเลขเท่านั้น',
      max:'กรุณากรอกตัวอักษรไม่เกิน10ตัว'
    }
  }
}
Validator.localize('en', dict)
export default {
  components: {
    vSelect
  },
  data () {
    return {
      status:false,
      kbankCode: '',
      deviceId : '',
      pinCode : '',
      bankno: '',
      fullname: '',
      username: '',
      password: '',
      phone: '',
      bankbank:'',
      typetype:'',
      bankOptions: [
        {
          label: 'รายการธนาคาร SCB',
          value: 'bank_transfer'
        },
        {
          label: 'รายการธนาคาร BAY',
          value: 'bank_transfer_bay'
        },
        {
          label: 'รายการธนาคาร GSB',
          value: 'bank_transfer_gsb'
        },
        {
          label: 'รายการธนาคาร KTB',
          value: 'bank_transfer_ktb'
        },
        {
          label: 'รายการธนาคาร KBANK',
          value: 'bank_transfer_kbank'
        },
        {
          label: 'บัญชี KKP',
          value: 'kkp'
        }
      ]
    }
  },
  computed: {
    activeUserInfo () {
      return this.$store.state.AppActiveUser
    }
  },
  methods: {
    async Save () {
      this.$validator.validateAll().then(async result => {
        if (result) {
          await axios
            .post('settingwallet/insertbankwithdraw', {
              bankno:this.bankno,
              fullname:this.fullname,
              username:this.username,
              password:this.password,
              phone:this.phone,
              bankbank:this.bankbank.value,
              pin: this.pinCode,
              code : this.kbankCode,
              deviceId :this.deviceId
            }).then(response => (this.status = response.data))
          if (this.status.status === false) {

            this.$vs.notify({
              time: 8000,
              color: 'danger',
              position: 'top-right',
              icon: 'error',
              title: 'บันทึกไม่สำเร็จ',
              text: this.status.info
            })

          } else {
            this.$vs.notify({
              time:3000,
              color:'success',
              position:'top-right',
              icon: 'check_box',
              title: this.status.info
            })

            setTimeout(() => { window.location.reload() }, 1500)
          }
        } else {
          this.$vs.notify({
            time:8000,
            color: 'danger',
            position:'top-right',
            icon:  'error',
            title:'ไม่ได้กรอกข้อมูล',
            text: 'กรุณากรอกข้อมูลให้ครบถ้วน'
          })

        }
      })

    },
    async Save2 () {
      this.$validator.validateAll().then(async result => {
        if (result) {
          await axios
            .post('settingwallet/insertbankwithdrawKkp', {
              bankno:this.bankno,
              fullname:this.fullname,
              username:this.username,
              password:this.password,
              phone:this.phone,
              bankbank:this.bankbank.value,
              pin: this.pinCode,
              code : this.kbankCode,
              deviceId :this.deviceId,
              ctz_id : this.ctz_id
            }).then(response => (this.status = response.data))
          if (this.status.status === false) {

            this.$vs.notify({
              time: 8000,
              color: 'danger',
              position: 'top-right',
              icon: 'error',
              title: 'บันทึกไม่สำเร็จ',
              text: this.status.info
            })

          } else {
            this.$vs.notify({
              time:3000,
              color:'success',
              position:'top-right',
              icon: 'check_box',
              title: this.status.info
            })

            setTimeout(() => { window.location.reload() }, 1500)
          }
        } else {
          this.$vs.notify({
            time:8000,
            color: 'danger',
            position:'top-right',
            icon:  'error',
            title:'ไม่ได้กรอกข้อมูล',
            text: 'กรุณากรอกข้อมูลให้ครบถ้วน'
          })

        }
      })

    },
    Reset () {
      this.bankno = ''
      this.fullname = ''
      this.username = ''
      this.password = ''
      this.phone = ''
      this.bankbank = ''
      this.reserve = ''
      this.pinCode = ''
      this.kbankCode = ''
      this.deviceId = ''
    }
  }
}

</script>
