<template>
  <vs-tabs :position="isSmallerScreen ? 'top' : 'left'" class="tabs-shadow-none" id="profile-tabs" :key="isSmallerScreen">

    <!-- GENERAL -->
    <vs-tab icon-pack="feather" icon="icon-user" :label="!isSmallerScreen ? 'SCB' : ''">
      <div class="tab-general md:ml-4 md:mt-0 mt-4 ml-0">
        <withdrawscb />
      </div>
    </vs-tab>
    <vs-tab icon-pack="feather" icon="icon-lock" :label="!isSmallerScreen ? 'GSB' : ''">
      <div class="tab-change-pwd md:ml-4 md:mt-0 mt-4 ml-0">
        <withdrawgsb />
      </div>
    </vs-tab>
    <vs-tab icon-pack="feather" icon="icon-lock" :label="!isSmallerScreen ? 'BAY' : ''">
      <div class="tab-change-pwd md:ml-4 md:mt-0 mt-4 ml-0">
        <withdrawbay />
      </div>
    </vs-tab>
    <vs-tab icon-pack="feather" icon="icon-lock" :label="!isSmallerScreen ? 'KTB' : ''">
      <div class="tab-change-pwd md:ml-4 md:mt-0 mt-4 ml-0">
        <withdrawktb />
      </div>
    </vs-tab>
      <vs-tab icon-pack="feather" icon="icon-lock" :label="!isSmallerScreen ? 'KBANK' : ''">
      <div class="tab-change-pwd md:ml-4 md:mt-0 mt-4 ml-0">
        <withdrawkbank />
      </div>
    </vs-tab>
    <vs-tab icon-pack="feather" icon="icon-lock" :label="!isSmallerScreen ? 'KKP' : ''">
      <div class="tab-change-pwd md:ml-4 md:mt-0 mt-4 ml-0">
        <withdrawkpp />
      </div>
    </vs-tab>
    <vs-tab v-if="$store.state.AppActiveUser.permissions.setting.action" icon-pack="feather" icon="icon-lock" :label="!isSmallerScreen ? 'เพิ่มบัญชีถอน' : ''">
      <div class="tab-change-pwd md:ml-4 md:mt-0 mt-4 ml-0">
        <insertwithdraw />
      </div>
    </vs-tab>
  </vs-tabs>


</template>

<script>
import Withdrawscb from './Withdrawscb.vue'
import Withdrawgsb from './Withdrawgsb.vue'
import Withdrawbay from './Withdrawbay.vue'
import Withdrawktb from './Withdrawktb.vue'
import Insertwithdraw from './InsertWithdraw.vue'
import withdrawkpp from './Withdrawkkp.vue'
import Withdrawkbank from './Withdrawkbank.vue'
export default {
  components: {
    Withdrawscb,
    Withdrawgsb,
    Withdrawbay,
    Withdrawktb,
    Insertwithdraw,
    Withdrawkbank,
    withdrawkpp
  },
  data () {
    return {
    }
  },
  computed: {
    isSmallerScreen () {
      return this.$store.state.windowWidth < 768
    }
  }
}
</script>

<style lang="scss">
#profile-tabs {
  .vs-tabs--content {
    padding: 0;
  }
}
</style>
